import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomePageComponent} from './components/home-page/home-page.component';
import {PersonalProjectsComponent} from './components/personal-projects/personal-projects.component';
import {TinkeringComponent} from './components/tinkering/tinkering.component';
import {PhotographyComponent} from './components/photography/photography.component';

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent
  },
  {
    path: 'projects',
    component: PersonalProjectsComponent
  },
  {
    path: 'tinkering',
    component: TinkeringComponent
  },
  {
    path: 'photography',
    component: PhotographyComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
