import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
{
  /**
   * Routing links
   */
  links = [
    {
      link: '',
      name: 'About'
    },
    {
      link: 'projects',
      name: 'Projects'
    },
    {
      link: 'tinkering',
      name: 'Tinkering'
    },
    {
      link: 'photography',
      name: 'Photography'
    },
    {
      name: 'Resume'
    }
  ];
}
