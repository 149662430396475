import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomePageComponent} from './components/home-page/home-page.component';
import {MatCardModule, MatSidenavModule} from '@angular/material';
import {PersonalProjectsComponent} from './components/personal-projects/personal-projects.component';
import {TinkeringComponent} from './components/tinkering/tinkering.component';
import {ProjectTemplateComponent} from './components/project-template/project-template.component';
import {PhotographyComponent} from './components/photography/photography.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    PersonalProjectsComponent,
    ProjectTemplateComponent,
    TinkeringComponent,
    PhotographyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatCardModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
