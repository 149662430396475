import { Component } from '@angular/core';

@Component({
  selector: 'app-tinkering',
  templateUrl: './tinkering.component.html',
  styleUrls: ['./tinkering.component.scss']
})
export class TinkeringComponent
{
  tinkeringProjects = [
    {
      title: 'Car Power System',
      subTitle: null,
      description: 'This project was created as a means of powering Arduino, Raspberry Pi, and Tessel 2 projects in my car. ' +
        'The majority of the system is setup behind my dash out of the way.',
      gitHubLink: 'https://github.com/RussellAFisher/Car-Projects/wiki/Part-1---Power-Setup',
      imagePath: 'CarPower.png'
    },
    {
      title: 'GPS Homing Lights',
      subTitle: '(Arduino)',
      description: 'The GPS homing light project is built around the Arduino Uno. Using a GPS module and programmed coordinates a 12 LED ' +
        'ring points towards "home". The LED color relates to your current heading and LED brightness to your distance from "home".',
      gitHubLink: 'https://github.com/RussellAFisher/Car-Projects/tree/master/GPS_Homing_Project',
      imagePath: 'GPSHoming.png'
    },
    {
      title: 'Fire Alarm Pi',
      subTitle: '(Raspberry Pi)',
      description: 'This project was created with a Raspberry Pi 2 as a means of tracing fire alarm system issues for older systems ' +
        'without built in memory. When a trouble light turns on, the system records a short video of the fire alarm panel with a time ' +
        'stamp and saves it to an external flash drive. If the state changes back to normal, another video is captured with a timestamp ' +
        'for comparison.',
      gitHubLink: 'https://github.com/RussellAFisher/Raspberry-Pi-Camera-Project',
      imagePath: 'FireAlarmPi.png'
    }
  ];

  constructor() {}
}
