import { Component } from '@angular/core';

@Component({
  selector: 'app-personal-projects',
  templateUrl: './personal-projects.component.html',
  styleUrls: ['./personal-projects.component.scss']
})
export class PersonalProjectsComponent
{
  codeProjects = [
    {
      title: 'Curiocity',
      subTitle: 'Built with Ionic, Cordova, AngularJS, Express, Heroku, Android and iOS',
      description: 'A lightweight native app to fulfill your curiosities. Curiocity lets you explore the home buying market around you. ' +
        'Curiocity can search for home information using your phones GPS to get the home address of the closest house to you, ' +
        'returning clean, concise results.',
      gitHubLink: 'https://github.com/RussellAFisher/Curiocity',
      youTubeLink: 'https://www.youtube.com/watch?v=cxN_hplqXXk',
      imagePath: 'CuriocityMockUp.png'
    },
    {
      title: 'Lorem Insults',
      subTitle: 'Built with Javascript',
      description: 'Lorem ipsum with a twist: Shakespearean and Olde English insults. An Atom package that allows you to input ' +
        'paragraphs of insults in place of the standard lorem ipsum with an Atom hot key.',
      gitHubLink: 'https://github.com/LoremInsults/lorem-insults',
      deployedLink: 'https://atom.io/packages/lorem-insults',
      imagePath: 'LoremInsults.gif'
    },
    {
      title: 'The Hive',
      subTitle: 'Built with JavaScript, jQuery, Express, SASS, Knex.js, PostgreSQL, Google OAuth, Node.js, HTML5',
      description: 'The Hive is an interactive, customizable social network for bee enthusiasts. Users are able to create a personal ' +
        'profile and upload observations of bees they\'ve spotted and superimpose their location onto a customized map ' +
        '(based on the Google maps API).',
      gitHubLink: 'https://github.com/TheHiveOrg/The-Hive',
      deployedLink: 'https://the-hive-g25.herokuapp.com/',
      youTubeLink: 'https://www.youtube.com/watch?v=ApFWHqMhabg',
      imagePath: 'TheHiveMockup.png'
    },
    {
      title: 'NASA Rover Imagery',
      subTitle: 'Built with JavaScript, jQuery, NASA API, HTML5',
      description: 'An imagery site that uses the NASA API to search for images from the Mars rover\'s ' +
        '(Curiosity, Spirit, and Opportunity). Has the functionality to narrow the search to specific rovers, camera angle and date, ' +
        'as well as cycle through all images for a given camera on a given day.',
      gitHubLink: 'https://github.com/RussellAFisher/Q1-NASA-Rover',
      deployedLink: 'http://russellafisher.github.io/',
      youTubeLink: 'https://www.youtube.com/watch?v=72wUfsAypfk',
      imagePath: 'NASARoverMockup.png'
    }
  ];

  constructor() {}
}
