import {Component} from '@angular/core';

@Component({
  selector: 'app-photography',
  templateUrl: './photography.component.html',
  styleUrls: ['./photography.component.scss']
})

export class PhotographyComponent
{
  photography = [
    '35mmBW01.JPG',
    '35mmBW02.JPG',
    '35mmBW03.JPG',
    'animal01.JPG',
    'animal02.JPG',
    'animal03.JPG',
    'closeUp01.JPG',
    'closeUp02.JPG',
    'closeUp03.JPG',
    'closeUp04.JPG',
    'closeUp05.JPG',
    'closeUp06.JPG',
    'industrial01.jpg',
    'industrial02.JPG',
    'industrial03.JPG',
    'industrial04.JPG',
    'landscape01.JPG',
    'landscape02.JPG',
    'landscape03.JPG',
    'landscape04.JPG',
    'landscape05.JPG',
    'landscape06.JPG',
    'landscape07.JPG',
    'landscape08.JPG',
    'nature01.JPG',
    'nature02.JPG',
    'nature03.JPG',
    'nature04.JPG',
    'nature05.JPG',
    'nature06.JPG',
    'nature07.JPG',
    'nature08.JPG',
    'nature09.JPG',
    'night01.jpg',
    'night02.JPG',
    'night03.JPG',
    'night04.JPG',
    'night05.jpg',
    'sunset01.JPG',
    'sunset02.JPG'];

  constructor()
  {
  }
}
